export default [
  {
    title: 'Carta porte',
    route: 'carta-porte',
    icon: 'FileIcon',
  },
  /*
  {
    title: 'Inicio',
    route: 'Inicio',
    icon: 'HomeIcon',
  },
  {
    title: 'Second Page',
    route: 'second-page',
    icon: 'FileIcon',
  },*/
]
