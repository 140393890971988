<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      <b-link class="ml-25" href="https://www.ecofactura.mx" target="_blank"
        >ecoFactura®
      </b-link>
      <span class="d-none d-sm-inline-block">
        es una marca registrada © 2009-{{ new Date().getFullYear() }}. Todos los
        derechos reservados.</span
      >
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
